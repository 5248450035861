/* Inicio Fonts */
@font-face {
  font-family: Avant-Book;
  src: url(./assets/Fonts/ITCAvantGardeStdBook.otf);
  font-display: swap;
}
@font-face {
  font-family: Avant-Medium;
  src: url(./assets/Fonts/ITCAvantGardeStdMd.otf);
  font-display: swap;
}
@font-face {
  font-family: Avant-Bold;
  src: url(./assets/Fonts/ITCAvantGardeStdBold.otf);
  font-display: swap;
}


@font-face {
  font-family: Work-Sans-Regular;
  src: url(./assets/Fonts/WorkSans-Regular.ttf);
  font-display: swap;
}

@font-face {
  font-family: Work-Sans-Bold;
  src: url(./assets/Fonts/WorkSans-SemiBold.ttf);
  font-display: swap;
}

@font-face {
  font-family: Work-Sans-Medium;
  src: url(./assets/Fonts/WorkSans-Medium.ttf);
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,200;0,400;0,500;1,200;1,300;1,400&display=swap');




/* Fin Fonts */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Work Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* productos */
.ReactCrop__image{
  max-height: 510px;
}

.MuiDialog-paper {
    margin: 5px !important;

}

.MuiStepConnector-root.MuiStepConnector-vertical.MuiStepConnector-active,
.MuiStepConnector-root.MuiStepConnector-vertical.MuiStepConnector-completed,
.MuiStepConnector-line.MuiStepConnector-lineVertical,
.MuiChip-root.MuiAutocomplete-tag.MuiChip-deletable{
  display: none;
}
.MuiStepContent-root{
/* padding:0px 5px 50px 5px !important; */
margin:0px !important;
border-left:  none !important;
}
/* .MuiDialog-paperFullWidth{
  width: 100% !important;
}
.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperFullWidth.MuiPaper-elevation24.MuiPaper-rounded{
  height: 90vh !important;
} */

.MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel.MuiPaper-elevation0{
  background-color:transparent;
}

.sign-in-form__or-span {
  font-size: 1.4rem;
  line-height: 1.42857;
  font-weight: 400;
  color: rgba(0,0,0,0.6);
  padding: 0 16px;
}
.sign-in-form__or-divider {
  margin: 16px 0;
  padding: 0 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.sign-in-form__or-divider:before, .sign-in-form__or-divider:after {
  background-color: rgba(0,0,0,0.35);
  content: '';
  height: 1px;
  width: 50%;
}
.sign-in-form__or-divider:before, .sign-in-form__or-divider:after {
  background-color: rgba(0,0,0,0.35);
  content: '';
  height: 1px;
  width: 50%;
}
.sign-in-form__third-party-container--visible {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}
.sign-in-form__third-party-container {
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  visibility: hidden;
}